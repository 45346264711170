<template>
  <div class="intercept">请在电脑端打开</div>
</template>

<script>
import { Modal } from "ant-design-vue";
import { defineComponent } from "vue-demi";

export default defineComponent({
  setup() {
    Modal.error({
      title: "请在电脑端打开",
    });
    return {};
  },
});
</script>

<style lang="less">
.intercept {
  padding-top: 100px;
  text-align: center;
  color: #cc2432;
}
</style>
